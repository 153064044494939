<template>
  <div
    id="container-menu"
    :style="{ 'background-color': color_mode }"
    :class="{ 'menu-hide': menuHide }"
  >
    <div class="w-100">
      <div class="w-100 d-flex justify-content-center align-items-center" id="logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <b-nav vertical class="w-100 mt-3">
        <!-- <b-nav-item
        v-b-toggle.agents
        class="position-relative"
        :class="{'is-active': subIsActive('/agentes')}"
        v-if="$acl.check('isAdmin')"> -->
        <b-nav-item
        v-b-toggle.agents
        v-if="$acl.check('isAdmin')"
        class="position-relative"
        :class="{'is-active': subIsActive('/agentes')}">
          <i class="far fa-id-badge"></i>
          Vendedores
          <i class="fas fa-angle-down icon-dropdown"></i>
          <b-collapse v-b-toggle.agents id="agents" class="mt-4">
            <ul>
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'agent-create' }">Novo Vendedor</router-link>
              </li>
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'agents' }">Lista de Vendedores</router-link>
              </li>
            </ul>
          </b-collapse>
        </b-nav-item>
        <b-nav-item
          class="position-relative"
          v-b-toggle.clients
          :class="{'is-active': subIsActive('/clientes')}"
        >
          <i class="fas fa-user"></i>
          Clientes <i class="fas fa-angle-down icon-dropdown"></i>

          <b-collapse :visible="clients" v-b-toggle.clients id="clients" class="mt-4">
            <ul class="">
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'clients-create' }">Novo Cliente</router-link>
              </li>
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'clients' }">Lista de Clientes</router-link>
              </li>
            </ul>
          </b-collapse>

        </b-nav-item>
        <b-nav-item
          v-b-toggle.projects
          :class="{'is-active': subIsActive('/projeto')}"
          class="position-relative"
        >
          <i class="fas fa-file-invoice-dollar"></i>
          Projetos <i class="fas fa-angle-down icon-dropdown"></i>
          <b-collapse :visible="projects" v-b-toggle.projects id="projects" class="mt-4">
            <ul class="">
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'project-create' }">Novo Projeto</router-link>
              </li>
              <li class="mb-3">
                <router-link class="pl-15" :to="{ name: 'projects' }">Lista de Projetos</router-link>
              </li>
            </ul>
          </b-collapse>
        </b-nav-item>
        <b-nav-item :to="{ name: 'users' }" v-if="$acl.check('isAdmin')">
          <i class="fas fa-user-cog"></i>
          Usuários
        </b-nav-item>
        <b-nav-item :to="{ name: 'admin' }" v-if="$acl.check('isAdmin')">
          <i class="fas fa-cogs"></i>
          Admin
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<style lang="css" scoped>
#container-menu {
  background: #333;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 330px;
  transition: left ease 0.5s;
}

.pl-15 {
  padding-left: 12px !important;
}

.icon-dropdown {
  position: absolute;
  right: 0;
  top: 21px;
}
ul {
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

ul li {
  list-style: none;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}
a {
  text-decoration: none !important;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

a:hover {
  color: #fff !important;
}
#container-menu ul li a {
  color: #d1cece;
  padding-left: 25px;
  padding-bottom: 17px;
  padding-top: 17px;
  font-weight: 300;
  transition: padding-left ease 0.5s;
  text-decoration: none !important;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#container-menu ul li {
  text-decoration: none !important;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#container-menu ul {
  text-decoration: none !important;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#container-menu div {
  text-decoration: none !important;
  touch-action: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#container-menu ul li a:hover {
  padding-left: 20px;
  background: #3c2c55;
  transition: all ease 0.5s;
}

#container-menu ul li a i {
  width: 32px;
  text-align: center;
  line-height: 24px;
  font-size: 25px;
  margin-bottom: 5px;
  margin-right: 15px;
}
.is-active {
    background: #3c2c55;
    color: #fff !important;

}

.azul {
  color: #101c79;
}
.menu-hide {
  left: -330px !important;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
}
</style>

<script>
export default {
  props: ['menuHide'],
  data() {
    return {
      active: false,
      color_mode: process.env.VUE_APP_COLOR_MODE,
      clients: false,
      projects: false,
      agents: false,
      users: false,
      menu: this.$route.meta.menu,
    };
  },
  methods: {
    handleLogout() {
      this.$auth.logout();
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },
  },
  watch: {
    menu: {
      immediate: true,
      handler(name) {
        this[name] = true;
      },
    },
  },
};
</script>
